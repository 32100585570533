// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, "/login"),
    resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
    page404: "/404",
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        projects: path(ROOTS_DASHBOARD, "/projects/overview"),
        nfts: path(ROOTS_DASHBOARD, "/nfts"),
        lottery: path(ROOTS_DASHBOARD, "/lottery"),
        staking: path(ROOTS_DASHBOARD, "/staking"),
        app: path(ROOTS_DASHBOARD, "/app"),
        ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
        analytics: path(ROOTS_DASHBOARD, "/analytics"),
        banking: path(ROOTS_DASHBOARD, "/banking"),
        booking: path(ROOTS_DASHBOARD, "/booking"),
        website: path(ROOTS_DASHBOARD, "/website"),
    },
    pols: {
        overview: path(ROOTS_DASHBOARD, "/pols/overview"),
        holder_assets: path(ROOTS_DASHBOARD, "/pols/holder_assets"),
    },
    idos: {
        overview: path(ROOTS_DASHBOARD, "/idos/overview"),
        top_wallets_roi: path(ROOTS_DASHBOARD, "/idos/top_wallets_roi"),
    },
    kpis: {
        poolside: path(ROOTS_DASHBOARD, "/kpis/poolside"),
        newsletter: path(ROOTS_DASHBOARD, "/kpis/newsletter"),
        blog: path(ROOTS_DASHBOARD, "/kpis/blog"),
    },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
