// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
    <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    blog: getIcon("ic_blog"),
    cart: getIcon("ic_cart"),
    chat: getIcon("ic_chat"),
    mail: getIcon("ic_mail"),
    user: getIcon("ic_user"),
    kanban: getIcon("ic_kanban"),
    banking: getIcon("ic_banking"),
    calendar: getIcon("ic_calendar"),
    ecommerce: getIcon("ic_ecommerce"),
    analytics: getIcon("ic_analytics"),
    dashboard: getIcon("ic_dashboard"),
    booking: getIcon("ic_booking"),
    lottery: getIcon("ic_lottery"),
    staking: getIcon("ic_staking"),
    idos: getIcon("ic_idos"),
    nfts: getIcon("ic_nfts"),
    projects: getIcon("ic_projects"),
    website: getIcon("ic_globe"),
};

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: "general",
        items: [
            {
                title: "KPIs",
                icon: ICONS.dashboard,
                children: [
                    {
                        title: "Poolside",
                        path: PATH_DASHBOARD.kpis.poolside,
                    },
                    {
                        title: "Newsletter",
                        path: PATH_DASHBOARD.kpis.newsletter,
                    },
                    {
                        title: "Blog",
                        path: PATH_DASHBOARD.kpis.blog,
                    },
                ],
            },
            {
                title: "Projects",
                path: PATH_DASHBOARD.general.projects,
                icon: ICONS.projects,
            },
            {
                title: "POLS",
                icon: ICONS.analytics,
                children: [
                    {
                        title: "Overview",
                        path: PATH_DASHBOARD.pols.overview,
                    },
                    {
                        title: "Holder Assets",
                        path: PATH_DASHBOARD.pols.holder_assets,
                    },
                ],
            },
            {
                title: "IDOs",
                icon: ICONS.idos,
                children: [
                    {
                        title: "Overview",
                        path: PATH_DASHBOARD.idos.overview,
                    },
                    {
                        title: "Top Wallets ROI",
                        path: PATH_DASHBOARD.idos.top_wallets_roi,
                    },
                ],
            },
            {
                title: "NFTs",
                path: PATH_DASHBOARD.general.nfts,
                icon: ICONS.nfts,
            },
            {
                title: "Lottery",
                path: PATH_DASHBOARD.general.lottery,
                icon: ICONS.lottery,
            },
            {
                title: "Staking",
                path: PATH_DASHBOARD.general.staking,
                icon: ICONS.staking,
            },
            {
                title: "Website",
                path: PATH_DASHBOARD.general.website,
                icon: ICONS.website,
            },
            // {
            //     title: "TODO3",
            //     path: PATH_DASHBOARD.general.ecommerce,
            //     icon: ICONS.dashboard,
            // },
            // {
            //     title: "TODO4",
            //     path: PATH_DASHBOARD.general.analytics,
            //     icon: ICONS.analytics,
            // },
            // {
            //     title: "TODO5",
            //     path: PATH_DASHBOARD.general.banking,
            //     icon: ICONS.banking,
            // },
            // {
            //     title: "TODO6",
            //     path: PATH_DASHBOARD.general.booking,
            //     icon: ICONS.booking,
            // },
        ],
    },
];

export default navConfig;
