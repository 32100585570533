import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense
            fallback={
                <LoadingScreen isDashboard={pathname.includes("/dashboard")} />
            }
        >
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: "auth",
            children: [
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                { path: "reset-password", element: <ResetPassword /> },
            ],
        },

        // Dashboard Routes
        {
            path: "dashboard",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
                    index: true,
                },
                {
                    path: "projects",
                    children: [
                        { path: "overview", element: <ProjectsData /> },
                        {
                            path: ":slug",
                            element: <ProjectsDataDetail />,
                        },
                    ],
                },
                { path: "pols/overview", element: <POLSData /> },
                {
                    path: "pols/holder_assets",
                    element: <POLSHolderAssetsData />,
                },
                { path: "lottery", element: <LotteryData /> },
                { path: "staking", element: <StakingData /> },
                {
                    path: "idos",
                    children: [
                        { path: "overview", element: <IDOData /> },
                        {
                            path: "top_wallets_roi",
                            children: [
                                {
                                    path: "",
                                    element: <IDOTopWalletsROIData />,
                                },
                                {
                                    path: ":address",
                                    element: <IDOTopWalletsROIDataDetail />,
                                },
                            ],
                        },
                    ],
                },
                { path: "nfts", element: <NFTData /> },
                { path: "website", element: <WebsiteData /> },
                {
                    path: "kpis",
                    children: [
                        { path: "poolside", element: <PoolsideKPIsData /> },
                        { path: "newsletter", element: <NewsletterKPIsData /> },
                        { path: "blog", element: <BlogKPIsData /> },
                    ],
                },
                { path: "app", element: <GeneralApp /> },
                { path: "ecommerce", element: <GeneralEcommerce /> },
                { path: "analytics", element: <GeneralAnalytics /> },
                { path: "banking", element: <GeneralBanking /> },
                { path: "booking", element: <GeneralBooking /> },
            ],
        },

        // Main Routes
        {
            path: "*",
            element: <LogoOnlyLayout />,
            children: [
                { path: "404", element: <NotFound /> },
                { path: "*", element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: "/",
            element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        },
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const ResetPassword = Loadable(
    lazy(() => import("../pages/auth/ResetPassword"))
);
// Dashboard
const GeneralApp = Loadable(
    lazy(() => import("../pages/dashboard/GeneralApp"))
);
const POLSData = Loadable(lazy(() => import("../pages/dashboard/POLSData")));
const POLSHolderAssetsData = Loadable(
    lazy(() => import("../pages/dashboard/POLSHolderAssetsData"))
);
const IDOTopWalletsROIDataDetail = Loadable(
    lazy(() => import("../pages/dashboard/IDOTopWalletsROIDataDetail"))
);
const LotteryData = Loadable(
    lazy(() => import("../pages/dashboard/LotteryData"))
);
const StakingData = Loadable(
    lazy(() => import("../pages/dashboard/StakingData"))
);
const WebsiteData = Loadable(
    lazy(() => import("../pages/dashboard/WebsiteData"))
);
const IDOData = Loadable(lazy(() => import("../pages/dashboard/IDOData")));
const NFTData = Loadable(lazy(() => import("../pages/dashboard/NFTData")));
const PoolsideKPIsData = Loadable(
    lazy(() => import("../pages/dashboard/PoolsideKPIsData"))
);
const NewsletterKPIsData = Loadable(
    lazy(() => import("../pages/dashboard/NewsletterKPIsData"))
);
const BlogKPIsData = Loadable(
    lazy(() => import("../pages/dashboard/BlogKPIsData"))
);
const ProjectsData = Loadable(
    lazy(() => import("../pages/dashboard/ProjectsData"))
);
const ProjectsDataDetail = Loadable(
    lazy(() => import("../pages/dashboard/ProjectsDataDetail"))
);
const IDOTopWalletsROIData = Loadable(
    lazy(() => import("../pages/dashboard/IDOTopWalletsROIData"))
);
const GeneralEcommerce = Loadable(
    lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
    lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
    lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
    lazy(() => import("../pages/dashboard/GeneralBooking"))
);
// Main
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
